const forWho = [
  {
    text: 'Для себя',
    value: 'for_me'
  },
  {
    text: 'Для сотрудника',
    value: 'for_employee'
  },
  {
    text: 'Для сотрудника не зарегистрированного в интре',
    value: 'for_new_employee'
  }
]

const contactTypes = [
  {
    id: 2,
    text: 'Телефон',
    value: 'phone'
  },
  {
    id: 3,
    text: 'Whatsapp',
    value: 'whatsapp'
  },
  {
    id: 4,
    text: 'Telegram',
    value: 'telegram'
  },
  {
    id: 5,
    text: 'E-mail',
    value: 'email'
  }
]

const typeOptions = [
  {
    id: 1,
    text: 'Заявка на ноутбук',
    value: 'laptop',
    fields: [
      {
        text: 'Основные задачи',
        type: 'checkbox',
        key: 'main_tasks',
        options: [
          { text: 'Работа с браузером (intra, google docs, …)', value: 'browser' },
          { text: 'Работа с документами', value: 'docs' },
          { text: 'Работа с графикой (PS Photoshop, Lightroom, …)', value: 'graphic' },
          { text: 'Программирование / Data аналитика / Power BI;', value: 'coding' },
          { text: 'Звонилка (СЗП)', value: 'call' },
          { text: 'Другое', value: 'otherTask', withText: true }
        ],
        other: [
          {
            text: 'Уточните для каких целей вам нужен ноутбук',
            type: 'text',
            key: 'otherText'
          }
        ]
      },
      {
        text: 'Диагональ экрана ноутбука',
        type: 'select',
        key: 'display_size',
        options: [
          { text: '14', value: '14' },
          { text: '15.6', value: '15' },
          { text: 'Не имеет значения', value: 'whatever' }
        ]
      },
      {
        text: 'Операционная система',
        type: 'select',
        key: 'os',
        options: [
          { text: 'Windows', value: 'windows' },
          { text: 'Ubuntu', value: 'ubuntu' },
          { text: 'MacOS (необходимо согласование с директором проекта)', value: 'mac' },
          { text: 'Не имеет значения', value: 'whatever' }
        ]
      },
      {
        text: 'Программы',
        type: 'checkbox',
        key: 'software',
        options: [
          { text: 'Infratell MightyCall', value: 'Infratell' },
          { text: 'MS Office (необходимо согласование с директором проекта)', value: 'office' },
          { text: 'Бесплатный аналог MS Office: FreeOffice', value: 'freeOffice' },
          { text: '1C', value: '1C' },
          { text: 'Пакет для работы с графикой Adobe PS, LR, CorelDraw', value: 'graphic' },
          { text: 'VPN (уточнить в разделе общий комментарий, для доступа к каким ресурсам он нужен)', value: 'vpn' },
          { text: 'Другое', value: 'otherSoftware', withText: true }
        ]
      },
      {
        text: 'Периферия',
        type: 'text',
        key: 'periphery'
      }
    ]
  },
  {
    id: 3,
    text: 'Заявка на монитор',
    value: 'display',
    fields: [
      {
        text: 'Опишите характеристики',
        type: 'text',
        key: 'displayText'
      }
    ]
  },
  {
    id: 4,
    text: 'Программное обеспечения',
    value: 'software',
    fields: [
      {
        text: 'Программы',
        type: 'checkbox',
        key: 'software',
        options: [
          { text: 'Infratell MightyCall', value: 'Infratell' },
          { text: 'MS Office (необходимо согласование с директором проекта)', value: 'office' },
          { text: 'Бесплатный аналог MS Office: FreeOffice', value: 'freeOffice' },
          { text: '1C', value: '1C' },
          { text: 'Пакет для работы с графикой Adobe PS, LR, CorelDraw', value: 'graphic' },
          { text: 'VPN (уточнить в разделе общий комментарий, для доступа к каким ресурсам он нужен)', value: 'vpn' },
          { text: 'Другое', value: 'otherSoftware', withText: true }
        ]
      }
    ]
  },
  {
    id: 5,
    text: 'Предоставление доступа',
    value: 'access',
    fields: [
      {
        text: 'Куда нужен доступ',
        type: 'select-field',
        key: 'accessType',
        options: [
          { text: 'Wi-Fi', value: 'wifi', withText: true },
          { text: 'VPN', value: 'vpn', withText: true },
          { text: 'Файловый сервер', value: 'fileServer', withText: true },
          { text: 'Карты для доступа в офис', value: 'passCard', withText: true }
        ],
        wifi: [
          {
            text: 'Комментарий',
            type: 'text',
            key: 'wifiText'
          }
        ],
        vpn: [
          {
            text: 'Комментарий',
            type: 'text',
            key: 'vpnText'
          }
        ],
        fileServer: [
          {
            text: 'Комментарий',
            type: 'text',
            key: 'fileServerText',
            required: true,
            placeholder:
              'Необходимо прописать полный сетевой путь до каталога(папки), куда нужен доступ. А также тип доступа: чтение или изменение (запись)'
          }
        ],
        passCard: [
          {
            text: 'Комментарий',
            type: 'text',
            key: 'passCardText'
          }
        ]
      }
    ]
  },
  {
    id: 6,
    text: 'Проблемы',
    value: 'troubles',
    fields: [
      {
        text: 'Тип проблемы',
        type: 'select-field',
        key: 'trouble',
        options: [
          { text: 'Проблема в личном ноутбуке/телефоне физического характера', value: 'hardware', withText: true },
          {
            text: 'Проблема в рабочем ноутбуке/телефоне физического характера',
            value: 'chocoHardware',
            withText: true
          },
          { text: 'Проблема с офисным Wi-Fi, локальной сетью', value: 'wifi', withText: true },
          { text: 'Нужен ваучер для WiFi на личное устройство', value: 'vaucher', withText: true },
          { text: 'Другое', value: 'other', withText: true }
        ],
        other: [
          {
            text: 'Опишите проблему',
            type: 'text',
            key: 'otherText'
          }
        ],
        hardware: [
          {
            text: 'Опишите проблему',
            type: 'text',
            key: 'hardwareText'
          }
        ],
        chocoHardware: [
          {
            text: 'Опишите проблему',
            type: 'text',
            key: 'chocoHardwareText'
          }
        ],
        wifi: [
          {
            text: 'Модель устройства',
            type: 'text',
            key: 'modelWifi'
          },
          {
            text: 'К какой сети подключено устройство',
            type: 'text',
            key: 'wifiName'
          },
          {
            text: 'Комментарий',
            type: 'text',
            key: 'comment'
          }
        ],
        vaucher: [
          {
            text: 'Ваучер',
            type: 'text',
            key: 'vaucherText'
          }
        ]
      }
    ]
  },
  {
    id: 7,
    text: 'Другое',
    value: 'others',
    fields: [
      {
        text: 'Описание',
        type: 'text',
        key: 'others'
      }
    ]
  },
  {
    id: 8,
    text: 'Доступ в офис по FaceID',
    value: 'faceid',
    fields: [
      {
        text: 'Имя (на латинице)',
        type: 'input',
        key: 'first_name_lat'
      },
      {
        text: 'Фамилия (на латинице)',
        type: 'input',
        key: 'last_name_lat'
      }
    ]
  }
]

export { forWho, contactTypes, typeOptions }
